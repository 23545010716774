import clsx from 'clsx'
import React, { useMemo } from 'react'
import PlusIcon from '@ancon/wildcat-ui/shared/icons/add-fill.svg'
import MinusIcon from '@ancon/wildcat-ui/shared/icons/minus-fill.svg'
import { UseControllerProps, useController } from 'react-hook-form'
import isNil from 'lodash/isNil'

import Button from './Button'
import Input, { InputProps } from './input/Input'
import FormInputErrorLabel from './FormInputErrorLabel'
import styles from './FormNumberModifierInput.module.scss'

type ReactHookFormRules = UseControllerProps['rules']

interface FormNumberModifierInputProps extends InputProps {
  /** Name of the field in form state */
  fieldName: string
  /* Validation rules of the input */
  rules?: ReactHookFormRules
  /* Class name of the container */
  containerClassName?: string
}

const DefaultNumberInputMin = 1
const DefaultNumberInputMax = 999

function isNumber(value: unknown) {
  if (isNil(value) || value === '') {
    return false
  }

  return !Number.isNaN(Number(value))
}

export default function FormNumberModifierInput({
  fieldName,
  rules,
  containerClassName,
  ...inputProps
}: FormNumberModifierInputProps) {
  const {
    field: { value: fieldValue, onChange, onBlur },
    fieldState: { error, isTouched },
  } = useController({
    name: fieldName,
    rules,
  })

  const [value, max, min] = useMemo(() => {
    let minValue = DefaultNumberInputMin
    let maxValue = DefaultNumberInputMax
    let derivedValue = DefaultNumberInputMin

    // Derive max and min values from rules
    if (rules) {
      // Derive minimum value
      if (typeof rules.min === 'object' && isNumber(rules.min.value)) {
        minValue = Number(rules.min.value)
      } else if (isNumber(rules.min)) {
        minValue = Number(rules.min)
      }

      // Derive maximum value
      if (typeof rules.max === 'object' && isNumber(rules.max.value)) {
        maxValue = Number(rules.max.value)
      } else if (isNumber(rules.max)) {
        maxValue = Number(rules.max)
      }
    }

    // Derive value according to the min and max values
    if (isNumber(fieldValue)) {
      derivedValue = Number(fieldValue)
    } else {
      derivedValue = minValue
    }

    return [derivedValue, maxValue, minValue]
  }, [fieldValue, rules])

  const isReachedMin = value <= min
  const isReachedMax = value >= max

  const hasError = !!error && isTouched

  function handleBlur() {
    if (value < min) {
      onChange(min)
    } else if (value > max) {
      onChange(max)
    }
    onBlur()
  }

  function handleIncreaseNumber(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()

    if (!isReachedMax) {
      onChange(value + 1)
    }
  }

  function handleDecreaseNumber(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()

    if (!isReachedMin) {
      onChange(value - 1)
    }
  }

  function handleChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value

    // Check whether text only contains whitespace
    if (text && !text.replace(/\s/g, '').length) {
      return
    }

    // Check whether text is a number
    if (isNumber(text)) {
      onChange(Number(text))
    }
  }

  return (
    <div className={clsx(styles.container, containerClassName)}>
      <div className={styles.inputContainer}>
        <Button
          variant="secondary"
          onClick={handleDecreaseNumber}
          className={clsx({
            [styles.disabled]: isReachedMin,
          })}
          disabled={isReachedMin}
          onBlur={handleBlur}
        >
          <MinusIcon className={styles.modifierIcon} />
        </Button>
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          value={value}
          onChange={handleChangeInput}
          onBlur={handleBlur}
          className={clsx(!hasError && styles.valid)}
        />
        <Button
          variant="secondary"
          onClick={handleIncreaseNumber}
          className={clsx({
            [styles.disabled]: isReachedMax,
          })}
          disabled={isReachedMax}
          onBlur={handleBlur}
        >
          <PlusIcon className={styles.modifierIcon} />
        </Button>
      </div>
      {hasError ? (
        <FormInputErrorLabel htmlFor={fieldName} error={error?.message} />
      ) : (
        <div className={styles.spacer} />
      )}
    </div>
  )
}
