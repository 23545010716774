import useTranslation from 'next-translate/useTranslation'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import { NewOrderStep } from '../../types'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { preOrderGoNextNewOrderStep } from '../../store/preOrderSlice'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { clientContextCustomerEmailSelector } from '../../../clientContext/store/clientContextSelectors'
import Input from '../../../app/components/input/Input'

import NewOrderHeader from './NewOrderHeader'
import NewOrderProceedButton from './NewOrderProceedButton'
import styles from './NewGroupOrderHostPreview.module.scss'

interface NewGroupOrderHostPreviewProps {
  isMobile?: boolean
}

export default function NewGroupOrderHostPreview({
  isMobile = false,
}: NewGroupOrderHostPreviewProps) {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  const customerEmail = useAppSelector(clientContextCustomerEmailSelector)

  function handleContinue() {
    dispatch(preOrderGoNextNewOrderStep(NewOrderStep.Summary))
    document.body.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.hostDetails}>
          <NewOrderHeader showOutlet isMobile={isMobile} />
          <HeadingText as="h3" color="heading-1">
            {t('newOrder.whoIsTheHost')}
          </HeadingText>
          <div className={styles.hostEmailField}>
            <BodyText as="label" color="body-1">
              {t('newOrder.hostEmail')}
            </BodyText>
            <Input readOnly value={customerEmail ?? ''} />
          </div>
        </div>
      </div>
      <NewOrderProceedButton
        className={styles.footer}
        onClick={handleContinue}
      />
    </div>
  )
}
