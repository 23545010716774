import { useMemo, useState } from 'react'
import SearchIcon from '@ancon/wildcat-ui/shared/icons/i-search.svg'
import useTranslation from 'next-translate/useTranslation'

import HeadingText from '../../../app/components/HeadingText'
import Input from '../../../app/components/input/Input'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  selectedPreOrderAttendeesSelector,
  selectedPreOrderMembersAttendanceSelector,
} from '../../store/preOrderSelectors'
import EmptyPlaceholder from '../../../app/components/emptyPlaceholder/EmptyPlaceholder'

import PreOrderAttendeeAccordion from './PreOrderAttendeeAccordion'
import styles from './PreOrderAttendeesSearchList.module.scss'

export default function PreOrderAttendeesSearchList() {
  const { t } = useTranslation('preOrder')

  const attendees = useAppSelector(selectedPreOrderAttendeesSelector)
  const attendance = useAppSelector(selectedPreOrderMembersAttendanceSelector)

  const [searchQuey, setSearchQuery] = useState<string>('')

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.target.value)
  }

  function handleClearSearch() {
    setSearchQuery('')
  }

  const filteredAttendees = useMemo(() => {
    if (searchQuey) {
      const searchRegex = new RegExp(searchQuey.toLowerCase())

      return attendees.filter(
        attendee => attendee.name.toLowerCase().search(searchRegex) >= 0,
      )
    }

    return attendees
  }, [attendees, searchQuey])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeadingText as="h3" color="heading-1">
          {t('attendees')}
        </HeadingText>
        {attendance > 0 && (
          <HeadingText as="h3" color="heading-1">
            &nbsp; &middot; &nbsp;{attendance}
          </HeadingText>
        )}
      </div>
      <Input
        leftAccessory={<SearchIcon />}
        containerClassName={styles.searchInput}
        highlighted
        placeholder={t('searchAttendee')}
        clearable={!!searchQuey}
        value={searchQuey}
        onChange={handleSearchChange}
        rightAccessoryOnClick={handleClearSearch}
      />
      <div className={styles.attendeeList}>
        {filteredAttendees.map(attendee => (
          <PreOrderAttendeeAccordion key={attendee.id} attendee={attendee} />
        ))}
        {filteredAttendees.length === 0 && (
          <EmptyPlaceholder
            title={t('noAttendeeFoundPlaceholder.title')}
            message={t('noAttendeeFoundPlaceholder.message')}
            className={styles.emptyAttendees}
          />
        )}
      </div>
    </div>
  )
}
